<template>
  <div class="pageContol largeScreen">
    <div style="width:100%">
      <header>
        <div class="header">
          <div
            style="width:40%;padding-left:5rem;margin-bottom: 10px;margin-top: 10px;"
          >
            <h3>北京晟融信息技术有限公司</h3>
            <h4>河北秦安安全科技股份有限公司</h4>
          </div>
          <div
            style="width:60%;display: flex;flex-direction: column;align-items: center;margin-bottom: 1rem;"
          >
            <h2 style="font-size:26px">学员明细</h2>
          </div>
          <div style="width:40%;margin-bottom: 10px;" class="weekBox">
            <h2 style="font-size:32px">{{ nowTime }}</h2>
            <div style="padding-right:0.675rem">
              <p>{{ nowWeek }}</p>
              <p>{{ nowDate }}</p>
            </div>
          </div>
        </div>
      </header>
      <div class="framePage" style="background:#03051b;height:91%">
        <section class="sectionBoxs framePage-body">
          <div class="operationControl" style="margin:0 0.657rem;">
            <div class="searchbox" style="margin-bottom:18px">
              <div title="查询条件" class="searchboxItem ci-full">
                <span class="itemLabel" style="color:#FFFFFF;font-weight: bold;"
                  >查询条件</span
                >
              </div>
              <div title="班级名称" class="searchboxItem ci-full">
                <span class="itemLabel" style="color:#FFFFFF">班级名称:</span>
                <el-input
                  v-model="projectName"
                  type="text"
                  size="small"
                  placeholder="请输入班级名称"
                  clearable
                />
              </div>
              <div title="学员搜索" class="searchboxItem ci-full">
                <span class="itemLabel" style="color:#FFFFFF">学员搜索:</span>
                <div
                  title="学员搜索"
                  style="display: flex; align-items: center"
                  class="searchboxItem ci-full"
                >
                  <span class="itemLabel">学员搜索:</span>
                  <el-select
                    v-model="userId"
                    placeholder="请选择"
                    no-data-text="没有数据"
                    remote
                    size="small"
                    clearable
                    :remote-method="superUserSelect"
                    @visible-change="clearUserSearchModel"
                  >
                    <template>
                      <div class="select-header">
                        <span
                          style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                          >学员姓名</span
                        >
                        <span
                          style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                          >身份证号码</span
                        >
                        <span
                          style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                          >学员电话</span
                        >
                      </div>
                      <div class="select-header">
                        <el-input
                          v-model="seaUserName"
                          v-on:input="superUserSelect"
                          type="text"
                          size="small"
                          placeholder="学员姓名"
                          clearable
                          style="width: 100px"
                        />
                        <el-input
                          v-model="seaUserIdcard"
                          v-on:input="superUserSelect"
                          type="text"
                          size="small"
                          placeholder="身份证号码"
                          clearable
                          style="margin-left: 50px; width: 140px"
                        />
                        <el-input
                          v-model="seaUserMobile"
                          v-on:input="superUserSelect"
                          type="text"
                          size="small"
                          placeholder="学员电话"
                          clearable
                          style="margin-left: 50px; width: 100px"
                        />
                      </div>
                    </template>
                    <el-option
                      v-for="item in userList"
                      :key="item.userId"
                      :label="item.userName"
                      :value="item.userId"
                    >
                      <span style="width: 100px; font-size: 13px">{{
                        item.userName
                      }}</span>
                      <span
                        style="width: 140px; margin-left: 50px; font-size: 13px"
                        >{{ item.idcard }}</span
                      >
                      <span
                        style="width: 100px; margin-left: 50px; font-size: 13px"
                        >{{ item.mobile }}</span
                      >
                    </el-option>
                  </el-select>
                </div>
              </div>

              <el-button
                class="bgc-large"
                style="margin:0 10px"
                @click="getDataList()"
                >查询</el-button
              >
            </div>
            <div class="searchbox" style="margin-bottom:18px">
              <el-button class="bgc-large" style="margin:0 10px" @click="back()"
                >返回</el-button
              >
            </div>
          </div>
          <div class="framePage-scroll">
            <div
              style="display: flex;flex-direction: column;flex:1;height:100%"
            >
              <ul style="display:flex;" class="ulTitle">
                <li>
                  <span style="max-width:80px">序号</span>
                  <span style="max-width:120px">学员姓名</span>
                  <span style="max-width:180px">身份证号</span>
                  <span style="max-width:120px">手机号</span>
                  <span>班级名称</span>
                  <span>课程名称</span>
                  <span style="max-width:120px">要求学时</span>
                  <span style="max-width:120px">已完成学时</span>
                  <span style="max-width:120px">考试成绩</span>
                  <span style="max-width:120px">学习状态</span>
                </li>
              </ul>
              <div @click="clickProps($event)">
                <vue-seamless-scroll
                  :data="tableDatas"
                  class="seamless-warp"
                  :class-option="classOption"
                >
                  <ul
                    style="display:flex;flex-direction: column;"
                    class="ulTitle ulcontent"
                  >
                    <li
                      v-for="(item, index) in tableDatas"
                      :class="{
                        single: index % 2 == 0,
                        double: index % 2 != 0,
                      }"
                      :key="index"
                    >
                      <span style="max-width:80px" :id="item.userId">{{
                        index + 1
                      }}</span>
                      <span style="max-width:120px" :id="item.userId">{{
                        item.userName
                      }}</span>
                      <span style="max-width:180px" :id="item.userId">{{
                        item.idcard
                      }}</span>
                      <span style="max-width:120px" :id="item.userId">{{
                        item.mobile
                      }}</span>
                      <span :id="item.userId">{{ item.projectName }}</span>
                      <span :id="item.userId">{{ item.courseName }}</span>
                      <span style="max-width:120px" :id="item.userId">{{
                        item.lessonNumDemand
                      }}</span>
                      <span style="max-width:120px" :id="item.userId">{{
                        item.finishLessonNum
                      }}</span>
                      <span style="max-width:120px" :id="item.userId">{{
                        item.paperScore || "--"
                      }}</span>
                      <span
                        style="max-width:120px"
                        :id="item.userId"
                        :style="{
                          color:
                            item.state == '10'
                              ? '#F53F8C'
                              : item.state == '20'
                              ? '#FFAE00'
                              : item.state == '30'
                              ? '#52FF97'
                              : item.state == '40'
                              ? '#CECECE'
                              : '',
                        }"
                        >{{ $setDictionary("LEARNINGSTATE", item.state) }}</span
                      >
                    </li>
                  </ul>
                </vue-seamless-scroll>
              </div>
            </div>
          </div>
        </section>
        <el-dialog
          class="dialog2"
          :visible.sync="dialog1"
          width="50%"
          top="10%"
          center
        >
          <div class="studentBox">
            <div
              style="width:10rem;height:3rem;    position: relative;left: 4rem;top: .5rem;"
            >
              <img
                :src="ruleForm.headPathUrl || require('@/assets/headpath.png')"
                alt=""
                style="width:3rem;height:3rem;border-radius:50%"
              />
            </div>
            <div style="width:100%">
              <el-form :inline="true" ref="ruleForm" label-position="left">
                <el-form-item label="姓名:" class="form-inline">
                  <p>{{ ruleForm.userName || "--" }}</p>
                </el-form-item>
                <el-form-item label="性别:" class="form-inline">
                  <p>
                    {{
                      ruleForm.sex == "1"
                        ? "男"
                        : ruleForm.sex == "0"
                        ? "女"
                        : "--"
                    }}
                  </p>
                </el-form-item>
                <el-form-item label="年龄:" class="form-inline">
                  <p>{{ ruleForm.age || "--" }}</p>
                </el-form-item>
                <el-form-item label="学历:" class="form-inline">
                  <p>{{ ruleForm.education || "--" }}</p>
                </el-form-item>
                <el-form-item label="专业:" class="form-inline">
                  <p>{{ ruleForm.profession || "--" }}</p>
                </el-form-item>
                <el-form-item label="职务/职称:" class="form-inline">
                  <p>{{ ruleForm.professionTitle || "--" }}</p>
                </el-form-item>
                <el-form-item label="人员类型:" class="form-inline">
                  <p>{{ ruleForm.userType || "--" }}</p>
                </el-form-item>
                <el-form-item label="部门:" class="form-inline">
                  <p>{{ ruleForm.department || "--" }}</p>
                </el-form-item>
                <el-form-item label="工种:" class="form-inline">
                  <p>{{ ruleForm.workType || "--" }}</p>
                </el-form-item>
                <el-form-item label="联系电话:" class="form-inline">
                  <p>{{ ruleForm.mobile || "--" }}</p>
                </el-form-item>
                <el-form-item label="身份证号:" class="form-inline">
                  <p>{{ ruleForm.idcard || "--" }}</p>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div style="height:350px;padding: 2rem;">
            <div
              style="display: flex;flex-direction: column;flex:1;height:100%"
            >
              <ul style="display:flex;" class="ulTitle">
                <li
                  class="projectNum"
                  style="border-bottom: 0.05rem solid #024058"
                >
                  <span style="max-width:12rem">培训时间</span>
                  <span>培训班级</span>
                  <span style="max-width:5rem">岗位名称</span>
                  <span style="max-width:4.5rem">培训学时</span>
                  <span style="max-width:4.5rem">培训结果</span>
                  <span style="max-width:4.5rem">学员档案</span>
                </li>
              </ul>
              <vue-seamless-scroll
                :data="TrainProjectList"
                class="seamless-warp"
                :class-option="classOption4"
              >
                <ul
                  style="display:flex;flex-direction: column"
                  class="ulTitle projectNumcontent"
                >
                  <li
                    v-for="(item, index) in ruleForm.list"
                    style="border-bottom: 0.05rem solid #024058"
                    :key="index"
                  >
                    <span style="max-width:12rem"
                      >{{ item.startDate }} - {{ item.endDate }}</span
                    >
                    <span>{{ item.projectName }}</span>
                    <span style="max-width:5rem">{{ item.postName }}</span>
                    <span style="max-width:4.5rem">{{
                      item.studyLessonNumMust
                    }}</span>
                    <span style="max-width:4.5rem">{{
                      item.studyState == "20" || item.studyState == "10"
                        ? "培训中"
                        : item.studyState == "30"
                        ? "已结业"
                        : item.studyState == "40"
                        ? "未结业"
                        : ""
                    }}</span>
                    <span style="max-width:4.5rem"
                      ><el-button
                        type="text"
                        @click="see(item)"
                        :disabled="item.state != '30'"
                        >预览档案</el-button
                      ></span
                    >
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
       
        </el-dialog>
        <el-dialog
          title="预览档案"
          :visible.sync="dialogDownload"
          width="50%"
          top="1%"
          class="dialogpdf"
          center
        >
          <div id="pdf-content" style="height: 800px"></div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
// import Empty from "@/components/Empty.vue";
// import PageNum from "@/components/PageNum.vue";
// import countTo from "vue-count-to";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
import scroll from "vue-seamless-scroll";
export default {
  name: "OneEnterpriseOneFile",
  components: {
    // countTo,
    // Empty,
    // PageNum,
  },
  mixins: [List],
  data() {
    return {
      dialogDownload: false,
      dialog1: false,
      ruleForm: {},
      nowDate: null, //存放年月日变量
      nowTime: null, //存放时分秒变量
      timer: "", //定义一个定时器的变量
      currentTime: new Date(), // 获取当前时间
      tableDatas: [],
      projectName: "",
      userId: "",
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
    };
  },
  created() {
    this.timer = setInterval(this.getTime, 1000);
    this.superUserSelect();
    this.projectName = this.$route.query.projectName;
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 15, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 56, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        singleHeight: 330,
        waitTime: 5000,
      };
    },
    classOption4() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 56, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        singleHeight: this.listAnimation == "20" ? 330 : 33,
        waitTime: this.listLoopSpace * 1000,
      };
    },
  },
  mounted() {
    this.getDataList();
  },
  watch: {
    userId: function() {
      this.superUserSelect();
    },
  },
  methods: {
    getDataList() {
      let params = {
        currentYear: this.$route.query.currentYear,
      };
      if (this.$route.query.parmar) {
        if (this.$route.query.parmar.jumpType == "2") {
          params.educationLevel = this.$route.query.parmar.params || "";
          params.projectState = this.$route.query.parmar.projectState || "";
        }
        if (this.$route.query.parmar.jumpType == "1") {
          params.ageArea = this.$route.query.parmar.params || "";
          params.projectState = this.$route.query.parmar.projectState || "";
        }
        if (this.$route.query.parmar.jumpType == "3") {
          params.postId = this.$route.query.parmar.params || "";
          params.projectState = this.$route.query.parmar.projectState || "";
        }
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.$route.query.projectState) {
        params.projectState = this.$route.query.projectState;
      }
      if (this.userId) {
        params.userId = this.userId;
      }
      this.$post("/largescreen/ls-config/studentPage", params).then((ret) => {
        console.log(ret);
        this.tableDatas = ret.data.list;
      });
    },
    superUserSelect(e) {
      this.$post(
        "/biz/user/largeScreenUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.superUserSelect();
      }
    },
    //控制行颜色
    tableRowClassName({ row, rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return "double"; //对应的类
      } else {
        return "single"; //对应的类
      }
    },
    getTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();
      const wk = date.getDay();
      this.month = check(month);
      this.day = check(day);
      this.hour = check(hour);
      this.minute = check(minute);
      this.second = check(second);
      function check(i) {
        const num = i < 10 ? "0" + i : i;
        return num;
      }
      let weeks = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let week = weeks[wk];
      this.nowDate = year + "年" + this.month + "月" + this.day + "日";
      this.nowTime = this.hour + ":" + this.minute + ":" + this.second;
      this.nowWeek = week;
    },
    back() {
      this.$router.back();
    },
    studentInfo(userId) {
      this.dialog1 = true;
      this.$post("/largescreen/ls-config/studentDetail", { userId })
        .then((ret) => {
          console.log(ret);
          this.ruleForm = {
            ...ret.data,
          };
        })
        .catch((err) => {
          return;
        });
    },
    clickProps(e) {
      console.log(e)
      if(e.target.id) {
      this.studentInfo(e.target.id); 
      }
    },
       /* 查看学习记录 */
    see(item) {
      let parmar = {
        projectId: item.projectId,
        userId: item.userId
      };
      this.$post("/eighteenEnterprise/enterpriseUser/downloadUserDocument", parmar).then(res => {
        if (res.status == 0) {
          this.dialogDownload = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-content");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    // see(item) {
    //   let parmar = {
    //     projectId: item.projectId,
    //     userId: item.userId,
    //   };
    //   this.$post("/cert/downloadTrainDocument", parmar).then((res) => {
    //     if (res.status == 0) {
    //       this.dialogDownload = true;
    //       this.$nextTick(() => {
    //         pdf.embed(res.data, "#pdf-content");
    //       });
    //     } else {
    //       that.$message.warning({ message: res.message, duration: 1500 });
    //     }
    //   });
    // },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    mouseOver() {
      console.log("77888899");
    },
  },
};
</script>
<style lang="less" scoped>
.bgc-large {
  background: rgba(8, 9, 36, 0);
  box-shadow: 0px 0px 88px 7px rgba(16, 108, 222, 0.22),
    0px 0px 11px 3px rgba(0, 114, 255, 0.8);
  border-radius: 10px;
  color: #fff;
  border: none;
}
header {
  height: 9%;
  color: #fff;
}
.header {
  display: flex;
  align-items: center;
  height: 100%;
  .weekBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    h2 {
      font-size: 1.5rem;
      margin-right: 1rem;
    }
  }
}
.sectionBoxs {
  height: 100%;
  background: transparent;
}

.el-form-item {
  width: 30%;
  margin-bottom: 0;
  height: 1.5rem;

  p {
    color: #fff;
  }
}
.projectNumcontent {
  font-size: 0.8rem;
  color: #ffffff;
}
.pageContol {
  background-image: url("../../assets/BG.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.studentBox {
  display: flex;
  padding: 2rem 0;
}
.el-form-item {
  width: 30%;
  margin-bottom: 0;
  height: 1.5rem;
}
.seamless-warp {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 0 1rem 0;
}
.ulTitle {
  font-size: 1rem;
  // padding: 0 2.35rem;
  li {
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #ffffff;
  height:33px;
  line-height:33px;
    cursor: pointer;
    span {
      text-align: left;
      flex: 1;
      // padding: 0.5rem 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 100%;
    }
  }
  .projectNum {
    span {
      color: #52ffff;
    }
  }
}
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
<style lang="less">
.largeScreen {
  // tr {
  //   pointer-events: none;
  // }
  .el-table tbody tr:hover > td {
    background-color: transparent !important;
  }
  .el-table,
  .el-table__expanded-cell {
    background: transparent;
  }
  .el-table .cell {
    color: #cecece;
  }
  .el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /*更改表格颜色*/
  .double {
    background: #141e38 !important;
  }
  .single {
    background: #03051b !important;
  }
  .el-dialog {
    background: transparent;
  }
  .el-dialog__header {
    display: none;
  }
  .dialog2 {
    .el-dialog__body {
      background-image: url("../../assets/dialogbg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 0;
    }
  }

  .studentspan {
    width: 4rem;
  }
  .dialogpdf {
    .el-dialog__body {
      background-image: url("../../assets/dialogbg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
     padding: 40px 20px 34px;
    }
  }
}
</style>
